<template>
  <v-container>
    <v-overlay
        absolute
        opacity="0.7"
        :value="empty"
    >
      <h1>{{$vuetify.lang.t('$vuetify.'+type+'.keine_daten_vorhanden')}}</h1>
    </v-overlay>
    <v-row>
      <v-col xl="10" lg="9" md="8" sm="6" cols="12">
        <h1>{{$vuetify.lang.t('$vuetify.'+type+'.header')}}</h1>
      </v-col>
      <v-col xl="2" lg="3" md="4" sm="6" cols="12" class="text-right">
        <v-switch color="secondary" v-model="kwh" :label="$vuetify.lang.t('$vuetify.'+type+'.kwh_switch')" class="mt-0" @change="updateUnit" v-if="showKwhSwitch" hide-details inset>
          <template v-slot:append>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="primary" v-on="on" v-bind="attrs">mdi-information</v-icon>
              </template>
              <span>{{$vuetify.lang.t('$vuetify.'+type+'.kwh_tooltip')}}</span>
            </v-tooltip>
          </template>
        </v-switch>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card :loading="loadingChart">
          <v-card-title>
            <v-row>
              <v-col cols="12" sm="12" md="4" lg="6" xl="8">
                {{$vuetify.lang.t('$vuetify.'+type+'.verbrauch_nach_raeumen')}}
              </v-col>
              <v-col sm="12" md="4" lg="3" xl="2">
                <v-select
                    :items="geraeteSelect"
                    v-model="selectedGeraet"
                    @change="filterGeraet"
                    outlined
                    dense
                    hide-details
                ></v-select>
              </v-col>
              <v-col cols="12" sm="12" md="4" lg="3" xl="2">
                <v-select
                    :items="comparisonSelect"
                    :disabled="comparisonDisabled"
                    v-model="selectedComparison"
                    @change="loadChart"
                    outlined
                    dense
                    hide-details
                ></v-select>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <BarChartVerbrauch ref="chart"/>
            <small v-if="hasErrors">
              {{$vuetify.lang.t('$vuetify.'+type+'.hinweis_fehler_verbrauch')}}
            </small>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card :loading="loadingGeraete">
          <v-card-title>
            <v-row>
              <v-col cols="10">
                {{$vuetify.lang.t('$vuetify.'+type+'.verbrauch_letzte_12_monate', $vuetify.lang.t('$vuetify.'+type+'.' + einheit))}}
              </v-col>
              <v-col cols="2" class="text-right">
                <v-btn icon @click="geraeteDialog = true">
                  <v-icon>mdi-cog</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text v-if="$vuetify.lang.t('$vuetify.'+type+'.erklaerung_rechnung')" v-html="$vuetify.lang.t('$vuetify.'+type+'.erklaerung_rechnung')"></v-card-text>
          <v-card-text>
            <v-data-table
                :headers="tableHeaders"
                :items="tableRows"
                mobile-breakpoint="300"
                hide-default-footer
                dense
            >
              <template v-slot:item="{ item }">
                <tr v-if="!item.geraet.portalunfaehig">
                  <td>
                    <strong v-if="item.geraet.label">
                      {{item.geraet.label.label}} ({{item.geraet.nr}})
                    </strong>
                    <strong v-else>
                      {{item.geraet.raum}} ({{item.geraet.nr}})
                    </strong>
                  </td>
                  <td v-for="messwert in item.messwerte" :key="messwert.id + '_' + messwert.monat">
                    {{messwert.verbrauchswert_delta_formatted}}
                    <v-tooltip bottom v-if="messwert.fehler_code">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon color="primary" v-on="on" v-bind="attrs">mdi-alert-circle</v-icon>
                      </template>
                      <span>{{$vuetify.lang.t('$vuetify.'+type+'.fehler_messwert_' + messwert.fehler_code)}}</span>
                    </v-tooltip>
                  </td>
                </tr>
              </template>
              <template v-slot:body.append>
                <tr>
                  <td class="text-start">
                    <strong>{{$vuetify.lang.t('$vuetify.'+type+'.gesamt')}}</strong>
                  </td>
                  <td v-for="(col, index) in totals" :key="index" class="text-start">
                    {{(new Intl.NumberFormat()).format(col)}}
                  </td>
                </tr>
              </template>
            </v-data-table>
            <p class="mt-3" v-if="portalunfaehigeGeraete.length > 0">* {{$vuetify.lang.t('$vuetify.'+type+'.portalunfaehige_geraete')}}</p>
          </v-card-text>
          <v-card-actions class="d-flex justify-end pb-4 px-4">
            <v-btn text outlined small @click="exportXls" :loading="excelExporting">
              {{$vuetify.lang.t('$vuetify.'+type+'.excel_export')}}
              <v-icon small class="ml-2">mdi-file-excel-outline</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="geraeteDialog" max-width="450">
      <v-form>
        <v-card>
          <v-card-title>
            {{$vuetify.lang.t('$vuetify.'+type+'.geraete_dialog_title')}}
          </v-card-title>
          <v-card-text>
            <p>{{$vuetify.lang.t('$vuetify.'+type+'.geraete_dialog_hinweis')}}</p>
            <v-row>
              <v-col sm="4">
                <strong>{{$vuetify.lang.t('$vuetify.'+type+'.geraete_nr')}}</strong>
              </v-col>
              <v-col sm="8">
                <strong>{{$vuetify.lang.t('$vuetify.'+type+'.geraete_bezeichnung')}}</strong>
              </v-col>
            </v-row>
            <v-row v-for="(item, index) in geraeteLabels" :key="item.id">
              <v-col sm="4">
                {{item.nr}}
              </v-col>
              <v-col sm="8">
                <v-text-field outlined hide-details dense v-model="geraeteLabels[index].label" :placeholder="geraeteLabels[index].placeholder"></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="d-flex justify-end">
            <v-btn depressed @click="geraeteDialog = false">
              {{$vuetify.lang.t('$vuetify.abbrechen')}}
            </v-btn>
            <v-btn depressed color="primary" @click="saveGeraeteLabel" :loading="savingLabels">
              {{$vuetify.lang.t('$vuetify.speichern')}}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-container>
</template>

<style lang="sass">
.theme--light.v-data-table tbody tr:nth-of-type(odd)
  background-color: #F2F2F2
.v-data-table
  table, thead th
    border-bottom: 2px #343B3B solid !important
    font-size: 13px !important
  thead th
    font-weight: bold
    color: #343B3B !important
  tbody td
    border-bottom: none

.v-icon.mdi-information
  cursor: help
</style>

<script>
import BarChartVerbrauch from '@/components/BarChartVerbrauch';
import Color from 'color';
import client from '@/utils/client';
import {mapState} from "vuex";
import moment from "moment";

export default {
  components: {
    BarChartVerbrauch,
  },
  props: ['type'],
  computed: mapState({
    vertrag: state => state.vertrag,
  }),
  data() {
    return {
      loadingChart: true,
      loadingGeraete: true,
      kwh: true,
      showKwhSwitch: false,
      einheit: 'kwh',
      otherUnit: null,
      geraeteDialog: false,
      selectedGeraet: '*',
      selectedComparison: 'vorjahr',
      comparisonDisabled: false,
      geraeteSelect: [
        {text: this.$vuetify.lang.t('$vuetify.'+this.type+'.alle_raeume'), value: '*'}
      ],
      comparisonSelect: [
        {text: this.$vuetify.lang.t('$vuetify.'+this.type+'.vergleich_vorjahr'), value: 'vorjahr'},
        {text: this.$vuetify.lang.t('$vuetify.'+this.type+'.vergleich_liegenschaft'), value: 'liegenschaft'}
      ],
      geraeteLabels: [],
      tableHeaders: [],
      tableRows: [],
      totals: [],
      savingLabels: false,
      excelExporting: false,
      empty: false,
      hasErrors: false,
      portalunfaehigeGeraete: [],
    };
  },
  watch: {
    '$store.state.vertrag.id': {
      handler() {
        this.selectedGeraet = '*';
        this.initializeUnits();
        this.loadAll();
      }
    }
  },
  mounted() {
    this.initializeUnits();
    this.loadAll();
  },
  methods: {
    initializeUnits() {
      if(this.type === 'heizenergie') {
        this.einheit = 've';
        this.otherUnit = 've';
        if (this.vertrag.wohneinheit.heizenergie_einheit === 'mixed') {
          this.showKwhSwitch = true;
          this.kwh = false;
        } else if (this.vertrag.wohneinheit.heizenergie_einheit === 'kwh') {
          this.showKwhSwitch = false;
          this.kwh = true;
          this.einheit = 'kwh';
        } else {
          this.showKwhSwitch = false;
          this.kwh = false;
        }
      } else if(this.type === 'warmwasser') {
        this.showKwhSwitch = true;
        this.kwh = false;
        this.einheit = 'm3';
        this.otherUnit = 'm3';
      } else {
        this.kwh = false;
        this.showKwhSwitch = false;
        this.einheit = 'm3';
        this.otherUnit = 'm3';
      }
    },
    updateUnit() {
      this.einheit = this.kwh ? 'kwh' : this.otherUnit;
      this.loadAll();
    },
    loadAll() {
      this.loadChart();
      this.loadGeraete();
    },
    loadChart() {
      this.loadingChart = true;
      let chartContext = this.$refs.chart.$children[0].$refs.canvasRef.getContext('2d');
      let grayGradient = chartContext.createLinearGradient(0, 0, 0, 300);
      grayGradient.addColorStop(0, '#99999933');
      grayGradient.addColorStop(1, '#99999900');
      let errorGradient = chartContext.createLinearGradient(0, 0, 0, 300);
      errorGradient.addColorStop(0, '#cccccc');
      errorGradient.addColorStop(1, '#999999');
      let primaryGradient = chartContext.createLinearGradient(0, 0, 0, 300);
      primaryGradient.addColorStop(0, this.$vuetify.theme.currentTheme.accent);
      let color = new Color(this.$vuetify.theme.currentTheme.accent);
      primaryGradient.addColorStop(1, color.darken(0.3));
      this.hasErrors = false;
      if(this.selectedGeraet === '*') {
        client.get('/vertraege/' + this.vertrag.id + '/messwerte/' + this.type, {limit: 12}).then(response => {
          let labels = [];
          let verbrauchswerte = [];
          let vergleich = [];
          let fehlerwerte = [];
          let errors = [];
          this.empty = !response.data.length;
          response.data.reverse();
          response.data.forEach(item => {
            labels.push(this.$vuetify.lang.t('$vuetify.datum.short_monat_' + item.monat) + ' ' + item.jahr.toString().substr(-2));
            if(item.errors) {
              verbrauchswerte.push(null);
              fehlerwerte.push(this.kwh ? item.kwh : item.verbrauchswert);
              errors.push(item.errors.split(','));
              this.hasErrors = true;
            } else {
              verbrauchswerte.push(this.kwh ? item.kwh : item.verbrauchswert);
              fehlerwerte.push(null);
              errors.push(null);
            }
            if (this.selectedComparison === 'vorjahr') {
              vergleich.push(this.kwh ? item.kwh_vorjahr : item.verbrauchswert_vorjahr);
            } else if (this.selectedGeraet === '*') {
              vergleich.push(this.kwh ? item.verbrauchswert_liegenschaft_qm_kwh : item.verbrauchswert_liegenschaft_qm);
            }
          });
          let datasets = [
            {
              label: this.$vuetify.lang.t('$vuetify.dashboard.verbrauch_aktuell'),
              data: verbrauchswerte,
              borderColor: this.$vuetify.theme.currentTheme.accent,
              backgroundColor: primaryGradient,
              hoverBackgroundColor: this.$vuetify.theme.currentTheme.primary,
              hoverBorderColor: this.$vuetify.theme.currentTheme.primary,
              borderRadius: 5,
              maxBarThickness: 40,
              stack: 'stack0',
              errors,
            },
            {
              label: this.$vuetify.lang.t('$vuetify.'+this.type+'.vergleich_' + this.selectedComparison),
              data: vergleich,
              borderWidth: 2,
              borderColor: '#E1E1E1',
              backgroundColor: grayGradient,
              pointBorderWidth: 2,
              pointHoverBackgroundColor: 'rgb(255, 255, 255)',
              pointHoverBorderWidth: 2,
              pointHoverRadius: 8,
              type: 'line',
              lineTension: 0,
              fill: true,
            }
          ];
          if(this.hasErrors) {
            datasets.push({
              label: this.$vuetify.lang.t('$vuetify.dashboard.verbrauch_eingeschraenkt'),
              data: fehlerwerte,
              borderColor: '#999',
              backgroundColor: errorGradient,
              hoverBackgroundColor: '#999',
              hoverBorderColor: '#999',
              borderRadius: 5,
              maxBarThickness: 40,
              stack: 'stack0',
              errors,
            });
          }
          this.$refs.chart.loadChartData({labels, datasets});
          this.loadingChart = false;
        }).catch(() => {
          this.loadingChart = false;
        });
      } else {
        client.get('/geraete/' + this.selectedGeraet + '/messwerte').then(response => {
          let labels = [];
          let verbrauchswerte = [];
          let vergleich = [];
          let fehlerwerte = [];
          let errors = [];
          response.data.reverse();
          response.data.forEach(item => {
            labels.push(this.$vuetify.lang.t('$vuetify.datum.short_monat_' + item.monat) + ' ' + item.jahr.toString().substr(-2));
            if(item.fehler_code) {
              verbrauchswerte.push(null);
              fehlerwerte.push(this.kwh ? item.verbrauchswert_delta_monatsende_kwh : item.verbrauchswert_delta_monatsende);
              errors.push([item.fehler_code]);
              this.hasErrors = true;
            } else {
              fehlerwerte.push(null);
              verbrauchswerte.push(this.kwh ? item.verbrauchswert_delta_monatsende_kwh : item.verbrauchswert_delta_monatsende);
              errors.push(null);
            }
            vergleich.push(this.kwh ? item.delta_kwh_vorjahr : item.delta_vorjahr);
          });
          let datasets = [
            {
              label: this.$vuetify.lang.t('$vuetify.dashboard.verbrauch_aktuell'),
              data: verbrauchswerte,
              borderColor: this.$vuetify.theme.currentTheme.accent,
              backgroundColor: primaryGradient,
              hoverBackgroundColor: this.$vuetify.theme.currentTheme.primary,
              hoverBorderColor: this.$vuetify.theme.currentTheme.primary,
              borderRadius: 5,
              maxBarThickness: 40,
              stack: 'stack0',
              errors,
            },
            {
              label: this.$vuetify.lang.t('$vuetify.'+this.type+'.vergleich_vorjahr'),
              data: vergleich,
              borderWidth: 2,
              borderColor: '#E1E1E1',
              backgroundColor: grayGradient,
              type: 'line',
              lineTension: 0,
              fill: true,
            }
          ];
          if(this.hasErrors) {
            datasets.push({
              label: this.$vuetify.lang.t('$vuetify.dashboard.verbrauch_eingeschraenkt'),
              data: fehlerwerte,
              borderColor: '#999',
              backgroundColor: errorGradient,
              hoverBackgroundColor: '#999',
              hoverBorderColor: '#999',
              borderRadius: 5,
              maxBarThickness: 40,
              stack: 'stack0',
              errors,
            });
          }
          this.$refs.chart.loadChartData({labels, datasets});
          this.loadingChart = false;
        }).catch(() => {
          this.loadingChart = false;
        });
      }
    },
    loadGeraete() {
      this.loadingGeraete = true;
      this.portalunfaehigeGeraete = [];
      client.get('/vertraege/' + this.vertrag.id + '/geraete/' + this.type).then(response => {
        this.geraeteLabels = [];
        this.geraeteSelect = [
          {text: this.$vuetify.lang.t('$vuetify.'+this.type+'.alle_raeume'), value: '*'}
        ];
        this.tableRows = [];
        this.tableHeaders = [
          {
            text: this.$vuetify.lang.t('$vuetify.'+this.type+'.raum_geraet_nr'),
            sortable: false,
            value: 'label',
          }
        ];
        this.totals = [];
        if(response.data.length) {
          response.data[0].messwerte.forEach((messwert, index) => {
            this.tableHeaders.push({
              text: this.$vuetify.lang.t('$vuetify.datum.short_monat_' + messwert.monat) + ' ' + messwert.jahr.toString().substr(-2),
              sortable: false,
              value: 'messwerte['+index+'].verbrauchswert_delta_formatted',
            });
            this.totals[index] = 0;
          });
          this.tableRows = response.data;
          response.data.forEach(item => {
            if(item.geraet.portalunfaehig) {
              this.portalunfaehigeGeraete.push(item);
            } else {
              this.geraeteLabels.push({
                nr: item.geraet.nr,
                id: item.geraet.id,
                label: item.geraet.label ? item.geraet.label.label : '',
                placeholder: item.geraet.raum,
              });
              this.geraeteSelect.push({
                text: (item.geraet.label ? item.geraet.label.label : item.geraet.raum) + " (" + item.geraet.nr + ")",
                value: item.geraet.id
              });
              item.messwerte.forEach((messwert, index) => {
                let delta = this.kwh ? messwert.verbrauchswert_delta_monatsende_kwh : messwert.verbrauchswert_delta_monatsende;
                messwert.verbrauchswert_delta_formatted = isNaN(delta) ? this.$vuetify.lang.t('$vuetify.' + this.type + '.keine_daten_short') : (new Intl.NumberFormat()).format(delta);
                this.totals[index] += isNaN(delta) ? 0 : delta;
              });
            }
          });
        }
        this.loadingGeraete = false;
      }).catch(() => {
        this.loadingGeraete = false;
      });
    },
    saveGeraeteLabel() {
      let data = {};
      this.savingLabels = true;
      this.geraeteLabels.forEach(item => {
        data[item.id] = item.label;
      });
      client.put('/geraete', data).then(() => {
        this.loadGeraete();
        this.savingLabels = false;
        this.geraeteDialog = false;
        this.$store.commit('snackbar/set', {show: true, color: 'success', message: this.$vuetify.lang.t('$vuetify.'+this.type+'.labels_speichern_erfolgreich')});
      }).catch(() => {
        this.savingLabels = false;
      });
    },
    filterGeraet() {
      if(this.selectedGeraet === '*') {
        this.comparisonDisabled = false;
      } else {
        this.selectedComparison = 'vorjahr';
        this.comparisonDisabled = true;
      }
      this.loadChart();
    },
    export(type) {
      client.get('/vertraege/' + this.vertrag.id + '/geraete/' + this.type, {kwh:this.kwh?1:0}, 'application/' + type).then(response => {
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(response.data);
        const wohneinheit = this.vertrag.wohneinheit;
        const date = moment(this.tableRows[0].messwerte[this.tableRows[0].messwerte.length - 1].datum_monatsende);
        link.download = this.$vuetify.lang.t('$vuetify.'+this.type+'.export_verbrauch', date.format('YYYY-MM'), wohneinheit.gebaeude.strasse, wohneinheit.gebaeude.hausnr, wohneinheit.gebaeude.ort, wohneinheit.geschosslage, type);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        this.excelExporting = false;
      }).catch(() => {
        this.excelExporting = false;
      });
    },
    exportXls() {
      this.excelExporting = true;
      this.export('xlsx');
    }
  }
};
</script>
