<template>
  <bar-chart :chart-data="verbrauch" :options="options" :height="400"/>
</template>

<script>
import { BarChart } from 'vue-chart-3';

export default {
  components: { BarChart },
  data() {
    return {
      verbrauch: {},
      options: {
        interaction: {
          intersect: false,
          mode: 'index',
        },
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          tooltip: {
            caretSize: 0,
            caretPadding: 18,
            backgroundColor: '#fff',
            borderColor: '#00000029',
            borderWidth: 1,
            titleColor: '#999',
            bodyColor: '#999',
            footerColor: '#999',
            xPadding: 9,
            yPadding: 12,
            usePointStyle: true,
            callbacks: {
              label: tooltipItem => {
                return tooltipItem.parsed.y === null ? null : tooltipItem.dataset.label + ': ' + tooltipItem.formattedValue;
              },
              labelColor: tooltipItem => {
                let dataset = tooltipItem.chart.config.data.datasets[tooltipItem.datasetIndex];
                return {
                  borderColor: '#fff',
                  backgroundColor: dataset.borderColor,
                };
              },
              footer: tooltipItems => {
                if(tooltipItems[0].dataset.errors.length) {
                  let errors = tooltipItems[0].dataset.errors[tooltipItems[0].dataIndex];
                  if(errors) {
                    let errorMessages = [];
                    errors.forEach(error => {
                      errorMessages.push(this.$vuetify.lang.t('$vuetify.heizenergie.fehler_messwert_' + error));
                    });
                    return errorMessages.concat("\n");
                  }
                }
              },
            }
          },
          legend: {
            position: 'bottom',
            display: true,
            align: 'start',
            labels: {
              usePointStyle: true,
              boxWidth: 15,
              padding: 30,
            }
          },
        },
        scales: {
          y: {
            beginAtZero: true,
            grid: {
              drawBorder: false,
              borderDash: [5,5],
              tickBorderDash: [0,1],
            }
          },
          x: {
            ticks: {
              padding: 10
            },
            grid: {
              drawTicks: false,
              display: false,
              drawBorder: false,
            }
          }
        },
      }
    };
  },
  methods: {
    loadChartData(data) {
      this.verbrauch = data;
    }
  }
}
</script>
